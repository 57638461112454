<template>
  <div class="uk-container uk-margin-medium-top">
    <h2>
      Elite Videos <span v-if="!loading">({{ videos.length }})</span>
    </h2>
    <div class="uk-grid">
      <div class="uk-width-1-5@m uk-margin-medium-bottom" v-for="(video, i) in videos" :key="`video-${i}`">
        <div class="uk-card uk-card-default">
          <div class="uk-card-media-top">
            <router-link :to="{ name: 'trader.elite-videos.show', params: { id: video.id }}">
              <img :src="video.thumbnail" alt="thumbnail">
            </router-link>
          </div>
          <div class="uk-padding-small">
            <router-link :to="{ name: 'trader.elite-videos.show', params: { id: video.id }}"><h4 class="uk-margin-remove">{{ video.title }}</h4></router-link>
            <router-link :to="{ name: 'trader.elite-videos.show', params: { id: video.id }}"><p class="uk-margin-remove uk-text-muted">{{ video.date }}</p></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      videos: [{}, {}, {}, {}, {}],
      loading: true,
    };
  },

  methods: {
    fetch() {
      this.$axios.get("/api/v1/trader/elite-videos").then((response) => {
        this.videos = response.data.videos;
        this.loading = false;
      });
    },
  },
};
</script>
